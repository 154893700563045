<div class="row">
  <div class="col-12 col-md">
    <div class="row">
      <div class="col">
        <div class="card bg-white">
          <div class="card-header text-center">
            <h4 class="mb-0">Add Transaction</h4>

            <div class="row">
              <div class="col text-start">
                <b>
                  <span class="text-muted ps-2">Transaction ID:</span>
                  <span class="ps-2 text-link">{{ transaction.id }}</span>
                </b>
              </div>
              <div class="col text-end"></div>
            </div>
          </div>
          <div class="card-body bg-warning">
            <div class="row">
              <div class="col-12">
                <div class="form-group mb-3">
                  <label for="transactionType" class="form-label"
                    >Transaction Type</label
                  >
                  <select
                    id="transactionType"
                    class="form-select bg-warning text-dark"
                    [(ngModel)]="transaction.transactionType"
                    name="transactionType"
                  >
                    <option value="">Select</option>
                    <option value="taulParchi">Taul Parchi</option>
                    <option value="truckLoading">Truck Loading Parchi</option>
                  </select>

                  <!-- Taul Parchi Transaction Details -->
                  <div *ngIf="transaction.transactionType === 'taulParchi'">
                    <label for="transactionDetails" class="form-label mt-3"
                      >Transaction Details</label
                    >
                    <select
                      id="transactionDetails"
                      class="form-select bg-warning text-dark"
                      [(ngModel)]="transactions.transactionDetails"
                      name="transactionDetails"
                    >
                      <option value="">Select</option>
                      <option
                        *ngFor="let parchi of TaulaParchi"
                        [value]="parchi._id"
                      >
                        Farmer: {{ parchi?.farmerDetails.name }} -- Crop:
                        {{ parchi?.cropDetails.name }} -- Weight:
                        {{ parchi?.netWeight }} -- Rate: {{ parchi?.rate }}
                      </option>
                    </select>
                  </div>

                  <!-- Truck Loading Parchi Transaction Details -->
                  <div *ngIf="transaction.transactionType === 'truckLoading'">
                    <label for="transactionDetails" class="form-label mt-3"
                      >Transaction Details</label
                    >
                    <select
                      id="transactionDetails"
                      class="form-select bg-warning text-dark"
                      [(ngModel)]="transactions.transactionDetails"
                      name="transactionDetails"
                    >
                      <option value="">Select</option>
                      <option
                        *ngFor="let parchi of TruckLoadingParchi"
                        [value]="parchi._id"
                      >
                        Farmer: {{ parchi?.partyDetails.name }} -- Crop:
                        {{ parchi?.cropDetails.name }} -- Weight:
                        {{ parchi?.netWeight }}
                      </option>
                    </select>
                  </div>

                  <label for="transactionStatus" class="form-label"
                    >Transaction Status</label
                  >
                  <select
                    id="transactionStatus"
                    class="form-select bg-warning text-dark"
                    [(ngModel)]="transaction.transactionStatus"
                    name="transactionStatus"
                  >
                    <option value="">Select</option>
                    <option value="active">Active</option>
                    <option value="inactive">Inactive</option>
                  </select>

                  <label for="transactionMode" class="form-label"
                    >Transaction Mode</label
                  >
                  <select
                    id="transactionMode"
                    class="form-select bg-warning text-dark"
                    [(ngModel)]="transaction.transactionMode"
                    name="transactionMode"
                  >
                    <option value="">Select Transaction Mode</option>
                    <option value="cash">Cash</option>
                    <option value="cheque">Cheque</option>
                    <option value="online">Online</option>
                    <option value="bank transfer">Bank Transfer</option>
                    <option value="UPI">UPI</option>
                  </select>

                  <label for="paymentStatus" class="form-label"
                    >Payment Status</label
                  >
                  <select
                    id="paymentStatus"
                    class="form-select bg-warning text-dark"
                    [(ngModel)]="transaction.PaymentStatus"
                    name="paymentStatus"
                  >
                    <option value="">Select Payment Status</option>
                    <option value="incoming">Incoming</option>
                    <option value="outgoing">Outgoing</option>
                  </select>

                  <!-- <label for="paidAmount" class="form-label">Paid Amount</label>
                  <input
                    id="paidAmount"
                    class="form-control bg-warning text-dark"
                    [(ngModel)]="transaction.paidAmount"
                    name="paidAmount"
                    type="number"
                    placeholder="Paid Amount"
                  />

                  <label for="totalAmount" class="form-label"
                    >Total Amount</label
                  >
                  <input
                    id="totalAmount"
                    class="form-control bg-warning text-dark"
                    [(ngModel)]="transaction.totalAmount"
                    name="totalAmount"
                    type="number"
                    placeholder="Total Amount"
                  />

                  <label for="remainingAmount" class="form-label"
                    >Remaining Amount</label
                  >
                  <input
                    id="remainingAmount"
                    class="form-control bg-warning text-dark"
                    [value]="transaction.remainingAmount"
                    name="remainingAmount"
                    type="number"
                    placeholder="Remaining Amount"
                  />

                  <label for="discount" class="form-label">Discount</label>
                  <input
                    id="discount"
                    class="form-control bg-warning text-dark"
                    [(ngModel)]="transaction.discount"
                    name="discount"
                    type="number"
                    placeholder="Discount"
                  /> -->
                  <label for="paidAmount" class="form-label">Paid Amount</label>
                  <input
                    id="paidAmount"
                    class="form-control bg-warning text-dark"
                    [(ngModel)]="transaction.paidAmount"
                    name="paidAmount"
                    type="number"
                    placeholder="Paid Amount"
                    (input)="calculateRemainingAmount()"
                  />

                  <label for="totalAmount" class="form-label"
                    >Total Amount</label
                  >
                  <input
                    id="totalAmount"
                    class="form-control bg-warning text-dark"
                    [(ngModel)]="transaction.totalAmount"
                    name="totalAmount"
                    type="number"
                    placeholder="Total Amount"
                    (input)="calculateRemainingAmount()"
                  />

                  <label for="remainingAmount" class="form-label"
                    >Remaining Amount</label
                  >
                  <input
                    id="remainingAmount"
                    class="form-control bg-warning text-dark"
                    [value]="transaction.remainingAmount"
                    name="remainingAmount"
                    type="number"
                    placeholder="Remaining Amount"
                    readonly
                  />

                  <label for="discount" class="form-label">Discount</label>
                  <input
                    id="discount"
                    class="form-control bg-warning text-dark"
                    [(ngModel)]="transaction.discount"
                    name="discount"
                    type="number"
                    placeholder="Discount"
                    (input)="calculateRemainingAmount()"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="card-actions bg-light border">
            <div class="text-end">
              <button
                class="btn btn-primary btn-lg m-3"
                (click)="saveTransaction()"
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
