<div class="row">
  <div class="col-12 col-md">
    <div class="row">
      <div class="col">
        <!-- Date time card -->
        <div class="card bg-white">
          <div class="card-body">
            <div class="d-flex justify-content-between align-items-center">
              <div
                class="d-flex justify-content-center align-items-center bg-dark rounded p-3 px-4"
              >
                <i class="fi fi-rs-calendar fs-3 text-white"></i>
              </div>
              <div class="text-end">
                <h3 class="mb-0 text-dark">
                  {{ currentDate | date : "dd-MM-YYYY" }}
                </h3>
                <p class="mb-0 text-secondary">Date</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Stats Card -->
    <div class="row mt-3">
      <div class="col-12 col-md-6">
        <div class="card bg-white">
          <div class="card-body">
            <div class="d-flex justify-content-between align-items-center">
              <div
                class="d-flex justify-content-center align-items-center bg-primary rounded p-3 px-4"
              >
                <i class="fi fi-rs-file fs-3 text-white"></i>
              </div>
              <div class="text-end">
                <h3 class="mb-0 text-dark">{{ TaulaParchiCount }}</h3>
                <p class="mb-0 text-secondary">Total Taula Parchi</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-6">
        <div class="card bg-white">
          <div class="card-body">
            <div class="d-flex justify-content-between align-items-center">
              <div
                class="d-flex justify-content-center align-items-center bg-primary rounded p-3 px-4"
              >
                <i class="fi fi-rs-list fs-3 text-white"></i>
              </div>
              <div class="text-end">
                <h3 class="mb-0 text-dark">{{ TruckLoadingParchiCount }}</h3>
                <p class="mb-0 text-secondary">Total Truck Loading Parchi</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12">
        <div class="card bg-white mt-4">
          <div class="card-header">
            <h4 class="mb-0">Total Crops</h4>
          </div>
        
          <!-- Bootstrap cards list -->
          <div class="card-body">
            <div class="row">
              <div class="col-12">
                <div class="table-responsive">
                  <div class="row">
                    <div class="col-md-4" *ngFor="let crop of Crops; let i = index">
                      <div class="card bg-white mb-3">
                        <div class="card-header">
                          <div class="text-center">
                            <h4 class="mb-0">{{ crop.crop_name }}</h4>
                            <p><b>M+M</b></p>
                          </div>
                          <h6 class="card-title">
                            <div class="row">
                              <div class="col text-start">
                                Sr.no: {{ crop._id }}
                              </div>
                            </div>
                          </h6>
                        </div>
                        <div class="card-body bg-warning">
                          <!-- Taul Parchi Grand Total -->
                          <div
                            class="border-bottom border-dark border-bottom-dotted mb-2 pb-2"
                          >
                            <h6 class="card-subtitle mb-2 text-muted">
                              Taul Parchi Grand Total:
                            </h6>
                            <p class="card-text">{{ crop.grandTotal }} kg</p>
                          </div>
                          <!-- Truck Loading Parchi Net Weight -->
                          <div
                            class="border-bottom border-dark border-bottom-dotted mb-2 pb-2"
                          >
                            <h6 class="card-subtitle mb-2 text-muted">
                              Truck Loading Net Weight:
                            </h6>
                            <p class="card-text">{{ Datas[i]?.totalBoraValue }} kg</p>
                          </div>
                          <!-- Difference in Weight -->
                          <div
                            class="border-bottom border-dark border-bottom-dotted mb-2 pb-2"
                          >
                            <h6 class="card-subtitle mb-2 text-muted">
                              Difference (Taul - Truck):
                            </h6>
                            <p class="card-text">
                              {{ crop.grandTotal - Datas[i]?.totalBoraValue }} kg
                            </p>
                          </div>
                          <!-- Rate for Taul Parchi -->
                          <!-- <div
                            class="border-bottom border-dark border-bottom-dotted mb-2 pb-2"
                          >
                            <h6 class="card-subtitle mb-2 text-muted">Taul Rate:</h6>
                            <p class="card-text">{{ crop.rate }}</p>
                          </div> -->
                          <!-- Rate for Truck Loading Parchi -->
                          <!-- <div
                            class="border-bottom border-dark border-bottom-dotted mb-2 pb-2"
                          >
                            <h6 class="card-subtitle mb-2 text-muted">
                              Truck Loading Rate:
                            </h6>
                            <p class="card-text">{{ Datas[i]?.rate }}</p>
                          </div> -->
                          <!-- Difference in Rate -->
                          <!-- <div
                            class="border-bottom border-dark border-bottom-dotted mb-2 pb-2"
                          >
                            <h6 class="card-subtitle mb-2 text-muted">
                              Rate Difference (Taul - Truck):
                            </h6>
                            <p class="card-text">
                              {{ crop.rate - Datas[i]?.rate }}
                            </p>
                          </div> -->
                        </div>
                      </div>
                    </div>
                    <!-- End of ngFor loop -->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Taula Parchis cards list -->
        <!-- <div class="card bg-white mt-4">
          <div class="card-header">
            <h4 class="mb-0">Taula Parchis</h4>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-12">
                <div class="table-responsive">
                  <div class="row">
                    <div
                      class="col-md-4"
                      *ngFor="let taulaParchi of TaulaParchi"
                    >
                      <div class="card bg-white mb-3">
                        <div class="card-header">
                          <div class="text-center">
                            <h4 class="mb-0">Taula Parchi</h4>
                            <p>
                              <b>M+M</b>
                            </p>
                          </div>
                          <h6 class="card-title">
                            <div class="row">
                              <div class="col text-start">
                                Sr.no: {{ taulaParchi._id }}
                              </div>
                              <div class="col text-end">
                                Date:
                                {{
                                  taulaParchi.created_at | date : "dd-MM-YYYY"
                                }}
                              </div>
                            </div>
                          </h6>
                        </div>
                        <div class="card-body bg-warning">
                          <div
                            class="border-bottom border-dark border-bottom-dotted mb-2 pb-2"
                          >
                            <h6 class="card-subtitle mb-2 text-muted">
                              Farmer:
                            </h6>
                            <p class="card-text">
                              {{ taulaParchi?.farmerDetails.name }}
                            </p>
                          </div>
                          <div
                            class="border-bottom border-dark border-bottom-dotted mb-2 pb-2"
                          >
                            <h6 class="card-subtitle mb-2 text-muted">
                              Village:
                            </h6>
                            <p class="card-text">
                              {{ taulaParchi?.villageDetails.name }}
                            </p>
                          </div>
                          <div
                            class="border-bottom border-dark border-bottom-dotted mb-2 pb-2"
                          >
                            <h6 class="card-subtitle mb-2 text-muted">
                              Firm/Company:
                            </h6>
                            <p class="card-text">
                              {{ taulaParchi.firm_company }}
                            </p>
                          </div>
                          <div
                            class="border-bottom border-dark border-bottom-dotted mb-2 pb-2"
                          >
                            <h6 class="card-subtitle mb-2 text-muted">Rate:</h6>
                            <p class="card-text">{{ taulaParchi.rate }}</p>
                          </div>
                          <div
                            class="border-bottom border-dark border-bottom-dotted mb-2 pb-2"
                          >
                            <h6 class="card-subtitle mb-2 text-muted">
                              Hammal:
                            </h6>
                            <p class="card-text">
                              {{ taulaParchi?.hammalDetails.name }}
                            </p>
                          </div>
                          <div
                            class="border-bottom border-dark border-bottom-dotted mb-2 pb-2"
                          >
                            <h6 class="card-subtitle mb-2 text-muted">Crop:</h6>
                            <p class="card-text">
                              {{ taulaParchi?.cropDetails.name }}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> -->
        <!-- Crop Net Weight Taula Parchis cards list -->
        <div *ngIf="Crops && Crops.length > 0">
          <div class="card bg-white mt-4">
            <div class="card-header">
              <h4 class="mb-0">Net Weight of Crop in Taula Parchi</h4>
            </div>

            <!-- Bootstrap cards list -->
            <div class="card-body">
              <div class="row">
                <div class="col-12">
                  <div class="table-responsive">
                    <div class="row">
                      <!-- Loop through Crops array -->
                      <div class="col-md-4" *ngFor="let crop of Crops">
                        <div class="card bg-white mb-3">
                          <div class="card-header">
                            <div class="text-center">
                              <h4 class="mb-0">{{ crop.crop_name }}</h4>
                              <p><b>M+M</b></p>
                            </div>
                            <h6 class="card-title">
                              <div class="row">
                                <div class="col text-start">
                                  Sr.no: {{ crop._id }}
                                </div>
                              </div>
                            </h6>
                          </div>
                          <div class="card-body bg-warning">
                            <div
                              class="border-bottom border-dark border-bottom-dotted mb-2 pb-2"
                            >
                              <h6 class="card-subtitle mb-2 text-muted">
                                NetWeight (Unit * Quantity):
                              </h6>
                              <p class="card-text">
                                {{ crop?.totalUnitBoraBoraQuantity }} kg
                              </p>
                            </div>
                            <div
                              class="border-bottom border-dark border-bottom-dotted mb-2 pb-2"
                            >
                              <h6 class="card-subtitle mb-2 text-muted">
                                Bharti Total:
                              </h6>
                              <p class="card-text">{{ crop.totalBharti }} kg</p>
                            </div>
                            <div
                              class="border-bottom border-dark border-bottom-dotted mb-2 pb-2"
                            >
                              <h6 class="card-subtitle mb-2 text-muted">
                                Grand Total:
                              </h6>
                              <p class="card-text">{{ crop.grandTotal }} kg</p>
                            </div>
                            <!-- <div
                              class="border-bottom border-dark border-bottom-dotted mb-2 pb-2"
                            >
                              <h6 class="card-subtitle mb-2 text-muted">
                                Rate:
                              </h6>
                              <p class="card-text">{{ crop.grandRate }}</p>
                            </div> -->
                          </div>
                        </div>
                      </div>
                      <!-- End of ngFor loop -->
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Truck Loading Parchis cards list -->
        <!-- <div class="card bg-white mt-4">
          <div class="card-header">
            <h4 class="mb-0">Truck Loading Parchis</h4>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-12">
                <div class="table-responsive">
                  <div class="row">
                    <div
                      class="col-md-4"
                      *ngFor="let truckLoadingParchi of TruckLoadingParchi"
                    >
                      <div class="card bg-white mb-3">
                        <div class="card-header">
                          <div class="text-center">
                            <h4 class="mb-0">Truck Loading Parchi</h4>
                            <p>
                              <b>M+M</b>
                            </p>
                          </div>
                          <h6 class="card-title text-dark">
                            <div class="row">
                              <div class="col text-start">
                                Sr.no: {{ truckLoadingParchi._id }}
                              </div>
                              <div class="col text-end">
                                Date:
                                {{
                                  truckLoadingParchi.created_at
                                    | date : "dd-MM-YYYY"
                                }}
                              </div>
                            </div>
                          </h6>
                        </div>
                        <div class="card-body bg-warning">
                          <div
                            class="border-bottom border-dark border-bottom-dotted mb-2 pb-2"
                          >
                            <h6 class="card-subtitle mb-2 text-muted">
                              Party Name:
                            </h6>
                            <p class="card-text">
                              {{ TruckLoadingDetails.partyName }}
                            </p>
                          </div>
                          <div
                            class="border-bottom border-dark border-bottom-dotted mb-2 pb-2"
                          >
                            <h6 class="card-subtitle mb-2 text-muted">
                              Vehicle Number:
                            </h6>
                            <p class="card-text">
                              {{ TruckLoadingParchi[0]?.vehicleNumber }}
                            </p>
                          </div>
                          <div
                            class="border-bottom border-dark border-bottom-dotted mb-2 pb-2"
                          >
                            <h6 class="card-subtitle mb-2 text-muted">
                              Delivery Location:
                            </h6>
                            <p class="card-text">
                              {{ TruckLoadingDetails?.deliveryLocation }}
                            </p>
                          </div>
                          <div
                            class="border-bottom border-dark border-bottom-dotted mb-2 pb-2"
                          >
                            <h6 class="card-subtitle mb-2 text-muted">
                              Hammal:
                            </h6>
                            <p class="card-text">
                              {{ TruckLoadingDetails?.assignedHammal }}
                            </p>
                          </div>
                          <div
                            class="border-bottom border-dark border-bottom-dotted mb-2 pb-2"
                          >
                            <h6 class="card-subtitle mb-2 text-muted">
                              Bora Nag:
                            </h6>
                            <p class="card-text">
                              {{ TruckLoadingParchi[0]?.boraQuantity }}
                            </p>
                          </div>
                          <div
                            class="border-bottom border-dark border-bottom-dotted mb-2 pb-2"
                          >
                            <h6 class="card-subtitle mb-2 text-muted">
                              Kaanta Weight:
                            </h6>
                            <p class="card-text">
                              {{ TruckLoadingParchi[0]?.netWeight }}
                            </p>
                          </div>
                          <div
                            class="border-bottom border-dark border-bottom-dotted mb-2 pb-2"
                          >
                            <h6 class="card-subtitle mb-2 text-muted">Crop</h6>
                            <p class="card-text">
                              {{ TruckLoadingDetails?.cropName }}
                            </p>
                          </div>
                          <div
                            class="border-bottom border-dark border-bottom-dotted mb-2 pb-2"
                          >
                            <h6 class="card-subtitle mb-2 text-muted">Rate:</h6>
                            <p class="card-text">
                              {{ TruckLoadingParchi[0]?.rate }}
                            </p>
                          </div>
                          <div
                            class="border-bottom border-dark border-bottom-dotted mb-2 pb-2"
                          >
                            <h6 class="card-subtitle mb-2 text-muted">
                              Other:
                            </h6>
                            <p class="card-text">
                              {{ TruckLoadingParchi[0]?.other }}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> -->
        
        
        <!-- Crop Net Weight Truck Loading Parchi cards list -->
        <div class="card bg-white mt-4">
          <div class="card-header">
            <h4 class="mb-0">Net Weight of Crop in Truck Loading Parchi</h4>
          </div>

          <div class="card-body">
            <div class="row">
              <div class="col-12">
                <div class="table-responsive">
                  <div class="row">
                    <!-- Loop through Datas array -->
                    <div class="col-md-4" *ngFor="let truck of Datas">
                      <div class="card bg-white mb-3">
                        <div class="card-header">
                          <div class="text-center">
                            <h4 class="mb-0">{{ truck.crop_name }}</h4>
                            <p><b>M+M</b></p>
                          </div>
                          <h6 class="card-title">
                            <div class="row">
                              <div class="col text-start">
                                Sr.no: {{ truck._id }}
                              </div>
                            </div>
                          </h6>
                        </div>

                        <div class="card-body bg-warning">
                          <div
                            class="border-bottom border-dark border-bottom-dotted mb-2 pb-2"
                          >
                            <h6 class="card-subtitle mb-2 text-muted">
                              Net Weight:
                            </h6>
                            <p class="card-text">
                              {{ truck.totalBoraValue }} kg
                            </p>
                          </div>
                          <!-- <div
                            class="border-bottom border-dark border-bottom-dotted mb-2 pb-2"
                          >
                            <h6 class="card-subtitle mb-2 text-muted">Rate:</h6>
                            <p class="card-text">
                              {{ truck.rate }}
                            </p>
                          </div> -->
                        </div>
                      </div>
                    </div>
                    <!-- End of ngFor loop -->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

       
      
       
        
       
        
      </div>
    </div>
  </div>
</div>
